import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Prisma GraphQL inputs type generator" description="Generate all Prisma inputs type for GraphQL" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h1 {...{
        "id": "graphql-sdl-inputs"
      }}>{`GraphQL SDL inputs`}</h1>
      <p>{`Generate all Prisma inputs type for GraphQL`}</p>
      <h2 {...{
        "id": "install"
      }}>{`Install`}</h2>
      <p><a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/plugins"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/v/@paljs/plugins.svg",
            "alt": "Version"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/plugins"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/dt/@paljs/plugins.svg",
            "alt": "Downloads/total"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://paljs.com/"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/l/@paljs/plugins.svg",
            "alt": "License"
          }}></img></a></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` i @paljs/plugins`}</code></pre></div>
      <h2 {...{
        "id": "use"
      }}>{`use`}</h2>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "js"
      }}><pre parentName="div" {...{
          "className": "language-js"
        }}><code parentName="pre" {...{
            "className": "language-js"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` mergeTypeDefs `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@graphql-tools/merge'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` sdlInputs `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`mergeTypeDefs`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`sdlInputs`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`customTypes`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "api"
      }}>{`api`}</h2>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`sdlInputs`}</code>{` function using a Prisma generated files `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`dmmf`}</code>{` to create `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`DocumentNode`}</code>{` have all your inputs type.`}</p>
      <p>{`takes one arg: you can pass object of options.`}</p>
      <h4 {...{
        "id": "example"
      }}>{`example`}</h4>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`OptionsType`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// if you use a custom generated files path you need to import dmmf from this path and send to our function through this option.`}</span>{`
  dmmf`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`DMMF`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Document`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// take an array of field names to exclude from any input type`}</span>{`
  `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`excludeFields`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// take a function and the input object as arg and return array of fields you want to generate`}</span>{`
  filterInputs`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`input`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`DMMF`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`InputType`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`DMMF`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`SchemaArg`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// by default when we create update inputs you will set data like {username: {set: "Ahmed"}} by making this option true you will be able to use it like {username: "Ahmed"} without set.`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// but you will also lose these options for number fields`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// increment: x: Adds x to the current value`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// decrement: x: Subtracts x from the current value`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// multiply: x: Multiplies the current value by x`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// divide: x: Divides the current value by x`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// set: x: Sets the value to x (equivalent to data: { age: 18 })`}</span>{`
  doNotUseFieldUpdateOperationsInput`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// example`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` dmmf `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./prismaCustomPath'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` mergeTypeDefs `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@graphql-tools/merge'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` sdlInputs `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`default`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`mergeTypeDefs`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`sdlInputs`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    dmmf`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    excludeFields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'password'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`filterInputs`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`input`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` input`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`fields`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`filter`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`field`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` field`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`name `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!==`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'passowrd'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`customTypes`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      